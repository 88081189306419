body {
    background-color: #F5F5F5;
}
#toDo{
    overflow-y: auto;
    position: relative;
    padding-bottom: 70px;
}
.dot{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}
#mainFooter{
    bottom: 0;
    position: fixed;
    width: 100%
}
.fetching{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    display: flex;
    color: #FFF;
    font-size: 5em;
    justify-content: center;
    align-items: center;
}
.spinner{
    animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
  }
